import React, { Component , Fragment} from 'react';
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from 'react-router-dom';
import './App.css';

import createClass from 'create-react-class';

import Filter from './components/Filter';
import OpenCall from './components/OpenCall';

var App = createClass({
    render: function () {
    return (
      <Router>
        <div id="app" className="App">
          <Fragment>
              <Switch>
              <Route exact path="/" exact render={() => (

                  <OpenCall></OpenCall>

                )} />
              <Route exact path="/filter" exact render={() => (

                  <Filter></Filter>

                )} />
                </Switch>
              </Fragment>
            </div>
          </Router>

        )}
})

export default App;
