import React from "react";
import {
  Link, Redirect
} from 'react-router-dom';
import Loader from 'react-loader-spinner'

class Filter extends React.Component {
  constructor () {
    super();
    this.state = {
      cWidth:"inherit",
      cHeight:"100%"
    }
  }
  
  componentDidMount () {
    var width = window.innerWidth;
    var height = window.innerHeight;
    var ratio = width/height;
    if (ratio > 4/3){
      this.setState({
        cWidth: "100%",
        cHeight: "inherit"
      });
    }else{
      this.setState({
        cWidth: "inherit",
        cHeight: "100%"
      });
    }

  }


  render () {

    return (
      <div className="App">
                <div className='vidContain' >
                    <div className='fullscreen-bg' style={{backgroundColor:"#e8e4ef"}}>
                    
                      <a href="https://www.instagram.com/a/r/?effect_id=1143469075984340&ch=ZTdmZDgxMDY5YWFiNWQzOGZiZDg3YzdjZWUwYmQ2MTA=">

                      <img src="https://lapses-screening.s3.amazonaws.com/daddy/spermic22-02.png" className="spermicBg" />
                      </a>
                    </div>

                    <div className="main_div">

                    </div>

                </div>
              </div>
    );
  }
}

export default Filter;