import React from "react";
import {
  Link, Redirect
} from 'react-router-dom';
import Loader from 'react-loader-spinner'

class OpenCall extends React.Component {
  constructor () {
    super();
    this.state = {
    	selectedIndex: 0,
    	ovumImg:"img/ovum.png",
    	diffDays:0,
    	display_dday:"none"
    }
    this.prevButtonClicked= this.prevButtonClicked.bind(this);
    this.nextButtonClicked= this.nextButtonClicked.bind(this);
    this.handleMouseEnter= this.handleMouseEnter.bind(this);
    this.handleMouseLeave= this.handleMouseLeave.bind(this);
    this.handleMouseEnter2= this.handleMouseEnter2.bind(this);
    this.handleMouseLeave2= this.handleMouseLeave2.bind(this);
  }
  
  componentDidMount () {
  	// this.sceneTransition();
  	// setTimeout(this.sceneTransition, 4000);
  	this.ddayCounter();
  	setInterval(this.ovumTransition, 5000);
  	document.getElementsByTagName("body")[0].setAttribute("style", "overflow-x:hidden;overflow-y:hidden;background:#d8e5f4;");
  	var carousel = document.querySelector('.carousel');
  	var carousel2 = document.querySelector('.carousel2');
	var cellHeight = carousel.offsetHeight;
	var cellHeight2 = carousel2.offsetHeight;
  	  var theta = 360 / 6;
  	  var theta2 = 360 / 8;
	  var cellSize = cellHeight;
	  var cellSize2 = cellHeight2;
  	var radius = Math.round( ( cellSize / 2) / Math.tan( Math.PI / 6 ) );
  	var radius2 = Math.round( ( cellSize2 / 2) / Math.tan( Math.PI / 8 ) );
	var angle = theta * this.state.selectedIndex * -1;
	var angle2 = theta2 * this.state.selectedIndex * -1;
	    var cells = carousel.querySelectorAll('.carousel__cell');
	    	  carousel.style.transform = 'translateZ(' + -radius + 'px) ' + 
	    	  'rotateX' + '(' + angle + 'deg)';
	    var cells2 = carousel2.querySelectorAll('.carousel__cell2');
	    	  carousel2.style.transform = 'translateZ(' + -radius2 + 'px) ' + 
	    'rotateX' + '(' + angle2 + 'deg)';
	    var reminder = this.state.selectedIndex % 6;
	    var reminder2 = this.state.selectedIndex % 8;
	  for ( var i=0; i < cells.length; i++ ) {
	    var cell = cells[i];
	    if ((i == reminder)||(i == reminder-1)){
	    	cell.style.zIndex = 3000;
	    	cell.style.opacity = 1;
	    }else if ((reminder<5)&&(i == reminder+1)){
	    	cell.style.zIndex = 2500;
	    	cell.style.opacity = .5;
	    }else if ((i == 5)){
	    	cell.style.zIndex = 2500;
	    	cell.style.opacity = .5;
	    }else{
	    	cell.style.zIndex = 2000;
	    	cell.style.opacity = .1;
	    }
	}
	for ( var i=0; i < cells2.length; i++ ) {
	    var cell2 = cells2[i];
	    if ((i == reminder2)||(i == reminder2-1)){
	    	cell2.style.zIndex = 3000;
	    }else if (i == reminder2+1){
	    	cell2.style.zIndex = 2500;
	    }else{
	    	cell2.style.zIndex = 2000;
	    }
	}
	  for ( var i=0; i < cells.length; i++ ) {
    var cell = cells[i];
    if ( i < 6 ) {
      // visible cell
      // cell.style.opacity = .1;
      var cellAngle = theta * i;
      cell.style.transform = 'rotateX' + '(' + cellAngle + 'deg) translateZ(' + radius + 'px)';
    } else {
      // hidden cell
      cell.style.opacity = 0;
      cell.style.transform = 'none';
    }
  }
  for ( var i=0; i < cells2.length; i++ ) {
    var cell2 = cells2[i];
    if ( i < 8 ) {
      // visible cell
      // cell2.style.opacity = .1;
      var cellAngle = theta2 * i;
      cell2.style.transform = 'rotateX' + '(' + cellAngle + 'deg) translateZ(' + radius2 + 'px)';
    } else {
      // hidden cell
      cell2.style.opacity = 0;
      cell2.style.transform = 'none';
    }
  }
  }

  rotateCarousel(pp) {
  	var carousel = document.querySelector('.carousel');
  	var carousel2 = document.querySelector('.carousel2');
	var cellHeight = carousel.offsetHeight;
	var cellHeight2 = carousel2.offsetHeight;
  	  var theta = 360 / 6;
  	  var theta2 = 360 / 8;
	  var angle = theta * this.state.selectedIndex * -1;
	  var angle2 = theta2 * this.state.selectedIndex * -1;
	  var cellSize = cellHeight;
	  var cellSize2 = cellHeight2;
  	var radius = Math.round( ( cellSize / 2) / Math.tan( Math.PI / 6 ) );
  	var radius2 = Math.round( ( cellSize2 / 2) / Math.tan( Math.PI / 8 ) );

	  carousel.style.transform = 'translateZ(' + -radius + 'px) ' + 
	    'rotateX' + '(' + angle + 'deg)';
	    carousel2.style.transform = 'translateZ(' + -radius2 + 'px) ' + 
	    'rotateX' + '(' + angle2 + 'deg)';
	    var cells = carousel.querySelectorAll('.carousel__cell');
	    var cells2 = carousel2.querySelectorAll('.carousel__cell2');
	    var reminder = this.state.selectedIndex % 6;
	    var reminder2 = this.state.selectedIndex % 8;
	  for ( var i=0; i < cells.length; i++ ) {
	    var cell = cells[i];
	    if ((i == reminder)||(i == reminder-1)){
	    	cell.style.zIndex = 3000;
	    	cell.style.opacity = 1;
	    }else if ((reminder<5)&&(i == reminder+1)){
	    	cell.style.zIndex = 2500;
	    	cell.style.opacity = .5;
	    }else if ((reminder==5)&&(i == 0)){
	    	cell.style.zIndex = 2500;
	    	cell.style.opacity = .5;
	    }else{
	    	cell.style.zIndex = 2000;
	    	cell.style.opacity = .1;
	    }
	}
	for ( var i=0; i < cells2.length; i++ ) {
	    var cell2 = cells2[i];
	    if ((i == reminder2)||(i == reminder2-1)){
	    	cell2.style.zIndex = 3000;
	    	cell2.style.opacity = 1;
	    }else if ((reminder2<7)&&(i == reminder2+1)){
	    	cell2.style.zIndex = 2500;
	    	cell2.style.opacity = .5;
	    }else if ((reminder2==7)&&(i == 0)){
	    	cell2.style.zIndex = 2500;
	    	cell2.style.opacity = .5;
	    }else{
	    	cell2.style.zIndex = 2000;
	    	cell2.style.opacity = .1;
	    }
	}
}

  prevButtonClicked(){
  	var newSelectedIndex = this.state.selectedIndex - 1;
  	this.setState({
  		selectedIndex : this.state.selectedIndex - 1
  	}, function() {this.rotateCarousel(this.state.selectedIndex)})
  }

  nextButtonClicked(){
  	var newSelectedIndex = this.state.selectedIndex + 1;
  	this.setState({
  		selectedIndex : this.state.selectedIndex + 1
  	},function() {this.rotateCarousel(this.state.selectedIndex)})
  }

  updateTransition() {
  var el = document.querySelector("div.inner_scene1");
   
  if (el) {
    el.className = "inner_scene";
  } else {
    el = document.querySelector("div.inner_scene");
    el.className = "inner_scene1";
  }
   
  return el;
}
ovumTransition(){
	var el = document.querySelector("img.ovum_img");
   
  if (el) {
    el.className = "ovum_img2";
  } else {
    el = document.querySelector("img.ovum_img2");
    el.className = "ovum_img";
  }
   
  return el;
}
sceneTransition(){
	var el = document.querySelector("div.scene_none");
   
  if (el) {
    el.className = "scene";
  } 
   
  return el;
}

handleMouseEnter(e){
    this.setState({
      ovumImg: "img/ovum_me.png"
    });
  }
  handleMouseLeave(){
  	this.setState({
      ovumImg: "img/ovum.png"
    });
  }

  handleMouseEnter2(e){
    this.setState({
      display_dday: "inherit"
    });
  }
  handleMouseLeave2(){
  	this.setState({
      display_dday: "none"
    });
  }
  ddayCounter(){

var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
var firstDate = new Date(2025,7-1,31);
var secondDate = new Date();
console.log(firstDate);
console.log(secondDate);

var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime())/(oneDay)));
this.setState({
	diffDays: diffDays
});

  }

  render () {

    return (
    	<div className="opencall_container" style={{ width:window.innerWidth, height:window.innerHeight}}>



    	<div className="container" style={{ width:window.innerWidth, height:window.innerHeight}}>
    	<img src={this.state.ovumImg} className="ovum_img" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} />
<div onMouseEnter={this.handleMouseEnter2} onMouseLeave={this.handleMouseLeave2}>
<img src="img/spermy.gif" className="sperm_img" />
<img src="img/spermy4.gif" className="sperm_img4" />
<img src="img/spermy3.gif" className="sperm_img3" />
<img src="img/spermy2.gif" className="sperm_img2" />
</div>
<div className="timeline_spermy" style={{display:"none"}}><b><i>{this.state.diffDays}</i></b> days have remained until fertilization ⤳⤳⤳⤳⤳</div>
	<div className="inner_scene1">
<img src="img/daddy_logo-16.png" className="img_apply2"  />

</div>
<div className="forAnimation">
	<div className="scene">

	  <div className="carousel">
	    <div className="carousel__cell">
	    <div className="carousel__cell_in" style={{textAlign:"center", paddingTop:"1%"}}>
	    <div className="title_small_text" style={{textAlign:"left"}}><span className="span_white" style={{fontWeight:"normal"}}> Nahee.app's family programming series</span></div>
	    <span className="span_white"> 

	   <span className="title">OPEN CALL FOR DADDIES</span><br /><br />
	    <span className="fancy_title"> Nahee is looking for daddy residents who will raise her future baby together.</span></span>
	    <div className="title_small_text" ><br /><span className="span_white" style={{fontWeight:"normal"}}> Application deadline is July 31 2025.</span></div>
	   <br /><br />
	    
</div>
</div>
	    <div className="carousel__cell">
	    <div className="carousel__cell_in">
	    <div className="carousel_title"><img src="img/introduction.png" className="each_title" /></div>
	    <div className="carousel_content"><span className="span_white">
Nahee Kim who is IRL extended version of Nahee.app plans to have a baby by herself in the near future with sperm donation. She is looking for daddy residents who are going to dedicate a certain amount of time in their life to raise the baby. Daddy residents will live together with Nahee and the baby in the same house which is supposed to be located in nice area either in South Korea or the United States close to nature and get stipend during their residency period. <br /><br />

The term residency, which is the term used for the programs run by art-related institutions to invite artists within their premises and offer conditions encouraging creativity, is applied in this ‘daddy residency’ also. The aim of this residency program is providing time and space for the applicants to think about their meaning of caring as a parent and creatively define the existing family system together. 
	    </span></div>
</div>
	    </div>
	    <div className="carousel__cell">
	    <div className="carousel__cell_in">
	    <div className="carousel_title"><img src="img/offering.png" className="each_title" /></div>
	    <div className="carousel_content">

	    <span className="span_white">
☞⇢ opportunity to experience parenting in real life for either 3 or 6 months<br /><br />
☞⇢ place to stay<br /><br />
☞⇢ stipend (about $700/month)<br /><br />
☞⇢ daddy master classes<br /><br />
☞⇢ invitations to annual family retreat<br /><br />
☞⇢ recommendation letter for each daddy’s future partner about their acheivement<br /></span>
</div>
	    </div>
	    </div>
	    <div className="carousel__cell">
	    <div className="carousel__cell_in">
	    <div className="carousel_title"><img src="img/requirements.png" className="each_title" /></div>
	    <div className="carousel_content">
	    <span className="span_white">
	    
☞⇢ older than 30 by the time the residency starts<br /><br />
☞⇢ speaking English or Korean fluently<br /><br />
☞⇢ attending all daddy master classes before the residency starts<br /><br />
☞⇢ relocating to Nahee’s house during the residency period<br /><br />
☞⇢ attending weekly parent meetings during the residency period<br /><br />
☞⇢ posting a baby journal every week during the residency period<br /><br /></span>
</div>
</div>
	    </div>
	    <div className="carousel__cell">
	    <div className="carousel__cell_in">
	    <div className="carousel_title"><img src="img/timeline.png" className="each_title" /></div>
	    <div className="carousel_content">
	    <span className="span_white">
	    
> application closed <div className="title_small_text" >(July 31 2025)</div><br />
> select candidates for interview<div className="title_small_text" >(Sep 2025)</div><br />
> interview1<div className="title_small_text" >(Oct 2025)</div> <br />
> interview2<div className="title_small_text" >(Mar 2026)</div> <br />
> final selection <div className="title_small_text" ></div> </span> 	 </div>   </div></div>
	    <div className="carousel__cell">
	    <div className="carousel__cell_in" >
	    <div className="carousel_title"><img src="img/faq.png" className="each_title" /></div>
	    <div className="carousel_content">

	    <span className="span_white">
Q1) What’s the difference between being a daddy resident and babysitting?<br />
<div className="title_small_text" >You will rear the baby with me. This residency is not for asking residents to care for the child instead of me. The actual time with the baby will be temporary but you will be one of the ‘parents’ of my child and will be asked to keep this relationship for the rest of your and my baby’s life (if you want to).</div> <br />

Q2) Is a married person also eligible to apply?<br />
<div className="title_small_text" >Yes.</div><br />

Q3) Does a daddy applicant have to be a male-identified person?<br />
<div className="title_small_text" >No, this residency is open to all gender.</div><br />

Q4) Why do you launch this open call seven years before the residency begins?<br />

<div className="title_small_text" >Becoming a parent is serious business and hard work. I want to give plenty of time to me and the applicants to think about whether they are ready to be a parent.</div><br />

Q5) What is daddy master class?<br />
<div className="title_small_text" >I plan to invite selected applicants’ inspirational caregivers in one place and ask them to give classes to the residents. The classes could happen in any format in which they(inspirational daddies) want to share their experience and knowledge about having and caring a baby.</div><br />
Q6) Who’s going to be the baby’s biological father?<br />
<div className="title_small_text" >I'm working on creating Daddy.app which is artificial intelligence to help Nahee develop her dynamic family in real life. Nahee and Daddy.app will dream together about their ideal baby. The features of the biological father of the baby will be determined based on what they are dreaming.</div><br />
Q7) What if Nahee marries someone before the start of the residency?<br />
<div className="title_small_text" >Nahee will try best to make this residency happen. Let's hope that her partner can understand this situation.</div><br />
<br /></span>
</div>
</div>
	    </div>
	    </div>
	</div>
	</div>

	<div className="carousel-options">

	  <p>
	    <button className="previous-button" onClick={(e)=>this.prevButtonClicked(e)}><img src="img/arrowup_t.png" className="arrowup" /></button>
	    <button className="next-button" onClick={(e)=>this.nextButtonClicked(e)}><img src="img/arrowdown_t.png" className="arrowdown" /></button>
	    <a href="https://forms.gle/HacnLpYr6ZgwUf1U6" className="next-button" target="_blank"> <img src="img/daddy_apply-14.png" className="applybtn" /><img src="img/daddy_arrow_apply.png" className="applyarrowimg" /></a>

	  </p>
	</div>
	<div className="share_link">
		    <a href="https://twitter.com/intent/tweet?text=OPEN%20CALL%20FOR%20DADDIES%20:%20%40AppNahee%20is%20launching%20open%20call%20for%20ᗪᗩᗪᗪY%20residents%20who%20want%20to%20raise%20her%20baby%20together.%20More%20info%20on%20Daddy%20Residency%20website%20daddy-residency.com%20pic.twitter.com/Mwz69bYelI" target="_blank">
		    <img src="img/tw.png" className="tw_img"/>

		    </a>
		    </div>
		    <div className="familyapp">
		    
	   <a href="https://github.com/nahbee10/nahee.app/blob/master/Family.app/Family.js" target="_blank"><img src="img/daddy.gif" className="familyapp_img" /></a>
	    </div>
    </div>

    <div className="container2" style={{display:"none",position:"fixed", top:"0", left:"0",width:window.innerWidth, height:window.innerHeight, background:"transparent", pointerEvents: 'none'}}>

	<div className="scene2">
	  <div className="carousel2">
	    <div className="carousel__cell2">
	    <div className="carousel__cell_in2">
	    <img src="img/daddy_objects-04.png" style={{}}/>
</div>
</div>
	    <div className="carousel__cell2">
	    <div className="carousel__cell_in2">
	    <img src="img/daddy_objects-06.png" style={{}}/>
	    <img src="img/daddy_objects-05.png" style={{}}/>
	    <img src="img/daddy_objects-07.png" style={{}}/></div>
	    </div>
	    <div className="carousel__cell2">
	    <div className="carousel__cell_in2">
	    <img src="img/daddy_objects-05.png" style={{}}/>
	    <img src="img/daddy_objects-06.png" style={{}}/>
	    <img src="img/daddy_objects-07.png" style={{}}/>
	    </div>
	    </div>
	    <div className="carousel__cell2">
	    <div className="carousel__cell_in2">
	    <img src="img/daddy_objects-07.png" style={{}}/>
	    <img src="img/daddy_objects-06.png" style={{}}/>
</div>
	    </div>
	    <div className="carousel__cell2">
	    <div className="carousel__cell_in2">
	    
	    <img src="img/daddy_objects-06.png" style={{}}/>
	    <img src="img/daddy_objects-07.png" style={{}}/>
	    </div>
	    </div>
	    <div className="carousel__cell2">
	    <div className="carousel__cell_in2">
	    <img src="img/daddy_objects-07.png" style={{}}/>
	    <img src="img/daddy_objects-06.png" style={{}}/>
	    <img src="img/daddy_objects-05.png" style={{}}/>
	    
</div>
	    </div>
	    </div>
	</div>

    </div>

    </div>


    );
  }
}

export default OpenCall;